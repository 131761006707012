import { WishlistItemRef } from "@afound/types";
import { animatePulse } from "@afound/common";
import { Unsubscribe } from "@reduxjs/toolkit";

import { ShowSiteNotification, SiteNotificationEventArgs } from "../../components/notification-bar";
import { isAuthenticated } from "../../shared/auth";
import { dispatchCustomEvent } from "../../shared/dom";
import { store } from "../../store";
import { WishlistOpenEvent } from "./types";
import { addToWishlist, removeFromWishlist, selectWishlist, selectWishlistConfig } from "../../store/wishlist-slice";

type WishlistButtonContext = "productlisting" | "productpage";

export class WishlistButton extends HTMLElement {
   private unsubscribe?: Unsubscribe;

   private wishlistButtonElem?: HTMLButtonElement;

   constructor() {
      super();

      const template = document.getElementById("wishlist-button") as HTMLTemplateElement;
      this.replaceChildren(template.content.cloneNode(true));

      this.wishlistButtonElem = this.children[0] as HTMLButtonElement;
   }

   static get observedAttributes() {
      return ["variantid", "offerid", "productcode", "epiproductkey", "iswishlistitem", "context"];
   }

   get variantId() {
      return this.getAttribute("variantid") || "";
   }
   set variantId(value: string) {
      this.setAttribute("variantid", value);
   }

   get offerId() {
      return this.getAttribute("offerid") || "";
   }
   set offerId(value: string) {
      this.setAttribute("offerid", value);
   }

   get productCode() {
      return this.getAttribute("productcode") || "";
   }
   set productCode(value: string) {
      this.setAttribute("productcode", value);
   }

   get epiProductKey() {
      return this.getAttribute("epiproductkey") || "";
   }
   set epiProductKey(value: string) {
      this.setAttribute("epiproductkey", value);
   }

   get isWishlistItem() {
      return this.hasAttribute("iswishlistitem");
   }
   set isWishlistItem(value: boolean) {
      if (value) {
         this.setAttribute("iswishlistitem", "");
         this.wishlistButtonElem?.classList.add("p12icon-heart--filled");
      } else {
         this.removeAttribute("iswishlistitem");
         this.wishlistButtonElem?.classList.remove("p12icon-heart--filled");
      }
   }

   get context() {
      return (this.getAttribute("context") || "") as WishlistButtonContext;
   }
   set context(value: WishlistButtonContext) {
      this.setAttribute("context", value);
   }

   connectedCallback() {
      this.addEventListener("click", this.handleWishlistClick);

      this.unsubscribe = store.subscribe(() => {
         const { lastAction, model } = selectWishlist(store.getState());
         if (lastAction !== "add" && lastAction !== "remove") {
            return;
         }

         this.isWishlistItem = model.items.some((i) => i.productCode === this.productCode);
      });
   }

   disconnectedCallback() {
      this.removeEventListener("click", this.handleWishlistClick);
      this.unsubscribe && this.unsubscribe();
   }

   attributeChangedCallback(name: string, oldValue: string, newValue: string) {
      if (oldValue === newValue) {
         return;
      }

      switch (name) {
         case "variantid":
            this.variantId = newValue;
            break;
         case "offerid":
            this.offerId = newValue;
            break;
         case "productcode":
            this.productCode = newValue;
            break;
         case "epiproductkey":
            this.epiProductKey = newValue;
            break;
         case "iswishlistitem":
            this.isWishlistItem = newValue === "";
            break;
         case "context":
            this.context = newValue as WishlistButtonContext;
            break;
      }
   }

   private setLoading = (isLoading: boolean) => {
      if (isLoading) {
         this.wishlistButtonElem!.disabled = true;
         this.wishlistButtonElem!.style.opacity = "0.5";
      } else {
         this.wishlistButtonElem!.disabled = false;
         this.wishlistButtonElem!.style.opacity = "1";
         animatePulse(this.wishlistButtonElem!);
      }
   };

   private notifyError = (message: string) => {
      if (this.context === "productlisting" || this.context === "productpage") {
         dispatchCustomEvent<SiteNotificationEventArgs>(this, ShowSiteNotification, {
            theme: "error",
            message,
         });
      }
   };

   private handleWishlistClick = async (ev: Event) => {
      ev.preventDefault();

      const isLoggedIn = await isAuthenticated();
      if (!isLoggedIn) {
         dispatchCustomEvent(this, WishlistOpenEvent);
         return;
      }

      const itemRef: WishlistItemRef = {
         variantId: this.variantId,
         productCode: this.productCode,
         offerId: this.offerId,
         epiProductKey: this.epiProductKey,
      };

      this.setLoading(true);

      if (this.isWishlistItem) {
         const {
            meta: { requestStatus },
         } = await store.dispatch(removeFromWishlist(itemRef));

         this.setLoading(false);

         if (requestStatus === "rejected") {
            const { translations } = selectWishlistConfig(store.getState());
            this.notifyError(translations.errorNotification!);
            return;
         }
      } else {
         const {
            payload,
            meta: { requestStatus },
         } = await store.dispatch(addToWishlist(itemRef));

         this.setLoading(false);

         if (requestStatus === "rejected") {
            const serverError = payload as string;
            if (serverError) {
               dispatchCustomEvent<SiteNotificationEventArgs>(this, ShowSiteNotification, {
                  theme: "warning",
                  message: serverError,
               });
            } else {
               const { translations } = selectWishlistConfig(store.getState());
               this.notifyError(translations.errorNotification!);
            }
         }
      }
   };
}

customElements.define("wishlist-button", WishlistButton);
