export const aiFeatureName = "my-pages";

export interface ServerRoute {
   path: string;
   component: string;
   routes: ServerRoute[];
}

export interface MyPagesServerConfig {
   country: any;
   locale: string;
   routes: Array<ServerRoute>;
   contentUrls: any;
   isMyDealsEnabled: boolean;
   enableEcomIdV2: boolean;
   isFlexibleReturnFeeEnabled: boolean;
   externalLoginSettings: any;
   translations: any;
   validations: any;
   appInsightsKey?: string;
   gtmKey?: string;
   contactUsWaitingDays: number;
   myPagesThreadTips: any;
   isMemberZoneEnabled: boolean;
}

export interface LocalizedApiResponse {
   success: boolean;
   message?: string;
}

export interface Link {
   text: string;
   url: string;
}
