import { FC, ReactNode, useState } from "react";
import { LoginModalServerConfig, LoginModalTexts } from "../types";
import { Form, Formik } from "formik";
import styles from "./register.module.scss";
import { useLogin } from "../use-login";
import { getNewsletterSignupEvent } from "../../../shared/tracking";
import { DepartmentPreference } from "../../../shared/types";
import { getCreateAccountGtmEvent, validateEmail, validatePassword } from "@afound/common";
import {
   Button,
   ButtonWrapper,
   Checkbox,
   ExternalLogin,
   Notification,
   InputField,
   RadioButton,
   withPasswordStrength,
} from "@afound/react";
import classNames from "classnames";
import * as yup from "yup";
import { MemberPerks } from "../../../components";

interface RegisterProps extends LoginModalServerConfig {
   returnUrl?: string;
}

interface RegisterFormModel {
   email: string;
   password: string;
   confirmPassword: string;
   directMarketingPolicy: boolean;
   departmentPreference?: DepartmentPreference;
}

const Perk: FC<{ label: string; children: ReactNode }> = ({ label, children }) => (
   <div className={styles.perk}>
      <span className={styles.perkIcon}>{children}</span>
      <span>{label}</span>
   </div>
);

const getPasswordSchema = (errorTexts: LoginModalTexts) =>
   yup.object({
      email: yup
         .string()
         .required(errorTexts.required)
         .test("emailFormat", errorTexts.emailFormat, (v) => !!v && validateEmail(v)),
      password: yup
         .string()
         .required(errorTexts.required)
         // Use empty span as "message" since Formik requires a non-empty error to show invalid validation state,
         // and to avoid showing a visible message to align with behavior in other places where we use the password
         // strength indicator.
         .test("passwordFormat", "<span></span>", (v) => !!v && validatePassword(v)),
      confirmPassword: yup
         .string()
         .required(errorTexts.required)
         .oneOf([yup.ref("password")], errorTexts.passwordMismatch),
      departmentPreference: yup.string().when("directMarketingPolicy", {
         is: true,
         then: (schema) => schema.required(errorTexts.departmentPreferenceRequired),
         otherwise: (schema) => schema.optional(),
      }),
   });

export const Register = (props: RegisterProps) => {
   const {
      locale,
      marketName,
      externalLoginSource,
      returnUrl,
      enableNewMemberPerks,
      texts: {
         memberPerksHeading,
         dealsPerk,
         earlyAccessPerk,
         discountCodesPerk,
         favoritesPerk,
         backInStockPerk,
         // TODO WZ: Remove once new perks are live
         memberPerk1,
         memberPerk2,
         memberPerk3,
         // TODO end
         email,
         createPassword,
         repeatPassword,
         passwordRequireMinLength,
         passwordRequireDigit,
         passwordRequireLowerCase,
         passwordRequireUpperCase,
         newsletterSignupHeading1,
         newsletterSignupHeading2,
         directMarketingPolicy,
         departmentPreference,
         departmentPreferenceMen,
         departmentPreferenceWomen,
         register: registerLabel,
         privacyNotice,
         or,
         registerWithSocial,
         generalError,
      },
   } = props;

   const [loading, setLoading] = useState(false);
   const [registerError, setRegisterError] = useState<string>();
   const [showDepartmentPref, setShowDepartmentPref] = useState(false);

   const { register } = useLogin();

   const handleSubmit = async (values: RegisterFormModel) => {
      const { directMarketingPolicy, departmentPreference = "", ...request } = values;

      setRegisterError(undefined);
      setLoading(true);

      const resp = await register({
         ...request,
         departmentPreference,
         languageName: locale,
         registerAndLogin: true,
         subscribeToNewsletter: directMarketingPolicy,
         subscribedFromFunction: "registeraccount:modal",
      });

      setLoading(false);

      if (!resp?.success) {
         setRegisterError(resp?.message || generalError);
         window.dataLayer.push(getCreateAccountGtmEvent(false, "registeraccount:modal"));
         return;
      }

      window.dataLayer.push(getCreateAccountGtmEvent(true, "registeraccount:modal"));

      if (!!directMarketingPolicy && resp.subscribedSuccessfully) {
         window.dataLayer.push(
            getNewsletterSignupEvent(
               "registeraccount:modal",
               departmentPreference as DepartmentPreference,
               undefined,
               "Email"
            )
         );
      }

      if (returnUrl) {
         window.location.href = returnUrl;
      } else {
         window.location.reload();
      }
   };

   const departmentsClasses = classNames({
      [styles.departments]: true,
      [styles.expanded]: showDepartmentPref,
   });

   const PasswordField = withPasswordStrength(InputField, {
      minLength: passwordRequireMinLength,
      digit: passwordRequireDigit,
      lowerCase: passwordRequireLowerCase,
      upperCase: passwordRequireUpperCase,
   });

   return (
      <div>
         <div className={styles.perksWrapper}>
            {enableNewMemberPerks ? (
               <MemberPerks
                  heading={memberPerksHeading}
                  {...{ dealsPerk, earlyAccessPerk, discountCodesPerk, favoritesPerk, backInStockPerk }}
               />
            ) : (
               <>
                  <h4>{memberPerksHeading}</h4>
                  <div className={styles.perks}>
                     <Perk label={memberPerk1}>
                        <span className="icon p12icon-deals" />
                     </Perk>
                     <Perk label={memberPerk2}>
                        <span className="icon p12icon-heart--filled" />
                     </Perk>
                     <Perk label={memberPerk3}>
                        <span className="icon p12icon-delivery-pink" />
                     </Perk>
                  </div>
               </>
            )}
         </div>
         <Formik
            initialValues={{
               email: "",
               password: "",
               confirmPassword: "",
               departmentPreference: "" as any,
               directMarketingPolicy: false,
            }}
            validationSchema={getPasswordSchema(props.texts)}
            onSubmit={handleSubmit}
         >
            <Form className={styles.registerForm}>
               <InputField
                  type="email"
                  name="email"
                  label={email}
                  formatOptions={{ forceLowerCase: true, trim: true }}
               />
               <PasswordField type="password" name="password" label={createPassword} errorAsHtml />
               <InputField type="password" name="confirmPassword" label={repeatPassword} />
               <div className={styles.signup}>
                  <h5 className={styles.signupHeading}>
                     {newsletterSignupHeading1}
                     <img className={styles.signupHeadingImg} src="/assets/images/svg/HM-Logo.svg" height="20" />
                     {newsletterSignupHeading2}
                  </h5>
                  <Checkbox
                     className={styles.directMarketing}
                     name="directMarketingPolicy"
                     onChange={() => setShowDepartmentPref((p) => !p)}
                  >
                     <span
                        className={styles.directMarketingTerms}
                        dangerouslySetInnerHTML={{ __html: directMarketingPolicy }}
                     />
                  </Checkbox>
                  <div className={departmentsClasses}>
                     <span>{departmentPreference}</span>
                     <div className={styles.departmentChoices}>
                        <RadioButton name="departmentPreference" label={departmentPreferenceMen} value="men" />
                        <RadioButton
                           name="departmentPreference"
                           label={departmentPreferenceWomen}
                           value="women"
                           hideError
                        />
                     </div>
                  </div>
               </div>
               <ButtonWrapper className={styles.buttonWrapper}>
                  <Button type="submit" disabled={loading}>
                     {registerLabel}
                  </Button>
               </ButtonWrapper>
               <Notification
                  className={styles.error}
                  visible={!!registerError}
                  theme="error"
                  message={registerError!}
               />
            </Form>
         </Formik>
         <div className={styles.privacyNotice} dangerouslySetInnerHTML={{ __html: privacyNotice }} />
         <div className={styles.externalLoginDivider}>{or}</div>
         <ExternalLogin
            locale={locale}
            marketName={marketName}
            signInWithButtonLabel={registerWithSocial}
            source={externalLoginSource}
            returnUrl={returnUrl}
         />
      </div>
   );
};
