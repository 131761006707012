export const aiFeatureName = "my-pages";

export interface ContactUsServerConfig {
   header: string;
   links: ContactUsLinks;
   texts: ContactUsTexts;
}

export type ContactUsTexts = {
   heading: string;
   contactFaq: string;
   contactEmail: string;
   contactChat: string;
   contactProfile: string;
   contactInstagram: string;
   contactFacebook: string;
   replyToEmail: string;
   orderTrackingTexts: OrderTrackingTexts;
   returnOrderTexts: ReturnOrderTexts;
}

export type ContactUsLinks = {
   faqPage: string; 
   ordersHistoryPage: string;
   digitalReturnUrl?:string;
}

export type OrderTrackingTexts = {
    heading: string;
    description: string;
    orderNumberPlaceholder: string;
    buttonText: string;
    orderNumber: string;
    packageLabel: string;
    soldAndShippedBy: string;
    trackDelivery: string;
    orderDetailsCount: string;
    orderDetailsHeading: string;
    trackYourOrderHelpText: string;
    notificationMessageWhenLoggedIn: string;
    notificationMessageWhenNotLoggedIn: string;
    required: string;
    noOrdersFound:string;
};

export type ReturnOrderTexts = {
    heading: string;
    description: string;
    buttonText: string;
};

export type Order = {
    logisticalId: string;
    commercialId: string;
    shippingTracking?: string;
    shippingTrackingUrl?: URL;
    shopId: number;
    shopName: string;
};