import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";

import { HeaderCommonElements, HeaderBaseProps } from "./types";
import { getContactUsGtmEvent } from "@afound/common";

interface MobileElements extends HeaderCommonElements {
   mobileMenuTrigger: HTMLElement;
   mobileMenuClose: HTMLElement;
   mobileMenuBack: HTMLElement;
   mobileMenuCurrent: HTMLElement;
}

interface MobileProps extends HeaderBaseProps<MobileElements> {}

interface CurrentMenuContext {
   label: string;
   url: string;
}

const Mobile = (props: MobileProps) => {
   const {
      elements: {
         mobileMenuTrigger,
         mobileMenuClose,
         mobileMenuBack,
         mobileMenuCurrent,
         header,
         headerMenuWrapper,
         headerMenu,
      },
      onNavigate,
   } = props;

   const defaultScrollContainer = header.querySelector(".header__submenu-scroll-container") as HTMLElement;

   const setUpTracking = () => {
      const contactUsLink = headerMenuWrapper.querySelector("#mobile-contact-us-link");
      if (!contactUsLink) {
         return;
      }

      const handleContactUsClick = () => {
         window.dataLayer.push(getContactUsGtmEvent("header"));
      };

      contactUsLink.addEventListener("click", handleContactUsClick);

      return () => contactUsLink.removeEventListener("click", handleContactUsClick);
   };

   const getCurrentMenuContext = (currentMenuLink?: Element): CurrentMenuContext | undefined =>
      currentMenuLink && {
         label: currentMenuLink.querySelector("span:first-child")!.innerHTML,
         url: currentMenuLink.getAttribute("href")!,
      };

   const setTopBarContent = (currentMenuContext?: CurrentMenuContext) => {
      const isTopLevel =
         !headerMenu?.querySelector(".header__submenu-wrapper.is-visible") &&
         !headerMenu?.querySelector(".header__category-wrapper.is-visible");

      // Set back button visibility
      mobileMenuBack.style.visibility = isTopLevel ? "hidden" : "visible";

      // Show/hide current subdepartment/category
      if (isTopLevel || !currentMenuContext) {
         mobileMenuCurrent.innerHTML = "";
         mobileMenuCurrent.setAttribute("href", "");
      } else {
         mobileMenuCurrent.innerHTML = currentMenuContext.label;
         mobileMenuCurrent.setAttribute("href", currentMenuContext.url);
      }
   };

   const showMobileMenu = () => {
      headerMenuWrapper?.classList.add("is-visible");
      disableBodyScroll(defaultScrollContainer);
   };

   const hideMobileMenu = () => {
      headerMenuWrapper?.classList.remove("is-visible");
      clearAllBodyScrollLocks();
   };

   const handleNavigateBack = () => {
      const activeCategoryMenu = headerMenu?.querySelector(".header__category-wrapper.is-visible");
      if (activeCategoryMenu) {
         activeCategoryMenu.classList.remove("is-visible");

         const activeParent = activeCategoryMenu.closest(".header__submenu-wrapper.is-visible");
         const activeParentLink = activeParent?.previousElementSibling || undefined;

         setTopBarContent(getCurrentMenuContext(activeParentLink));
         return;
      }

      const activeSubmenu = headerMenu?.querySelector(".header__submenu-wrapper.is-visible");
      if (activeSubmenu) {
         activeSubmenu.classList.remove("is-visible");
         setTopBarContent();
      }
   };

   const handleCurrentMenuItemClick = (ev: MouseEvent) => {
      const target = ev.target as HTMLElement;

      if (!target) {
         return;
      }

      const departmentLink = headerMenu?.querySelector(".header__submenu-wrapper.is-visible")?.previousElementSibling;
      const subdepartmentLink = headerMenu?.querySelector(
         ".header__category-wrapper.is-visible"
      )?.previousElementSibling;

      onNavigate({
         trigger: subdepartmentLink ? "category" : "subdepartment",
         trackingKeys: {
            department: departmentLink?.querySelector("span")?.innerHTML || "",
            subdepartment: subdepartmentLink?.querySelector("span")?.innerHTML,
         },
      });
   };

   const handleMenuItemClick = (ev: MouseEvent) => {
      const target = ev.target as HTMLElement;

      const brandLink = target.closest(".top-brands__grid-cell");
      if (brandLink) {
         return;
      }

      const promotedItem = target.closest(".header__promoted-item");
      if (promotedItem) {
         return;
      }

      const categoryItem = target.closest(".header__category-item");
      if (categoryItem) {
         return;
      }

      const submenuItem = target.closest(".header__submenu-item");
      if (submenuItem) {
         if (submenuItem.classList.contains("has-children")) {
            ev.preventDefault();

            disableBodyScroll(submenuItem.querySelector(".header__category-wrapper") as HTMLElement);

            submenuItem.querySelector(".header__category-wrapper")?.classList.add("is-visible");
            const submenuItemLink = submenuItem?.querySelector(".header__nav-link") || undefined;

            setTopBarContent(getCurrentMenuContext(submenuItemLink));
         }
         return;
      }

      const menuItem = target.closest(".header__menu-item");
      if (menuItem) {
         if (menuItem.classList.contains("has-children")) {
            ev.preventDefault();

            disableBodyScroll(menuItem.querySelector(".header__submenu-scroll-container") as HTMLElement);

            menuItem.querySelector(".header__submenu-wrapper")?.classList.add("is-visible");
            const menuItemLink = menuItem?.querySelector(".header__nav-link") || undefined;

            setTopBarContent(getCurrentMenuContext(menuItemLink));
         }
      }
   };

   mobileMenuTrigger.addEventListener("click", showMobileMenu);
   mobileMenuClose.addEventListener("click", hideMobileMenu);
   mobileMenuBack.addEventListener("click", handleNavigateBack);
   mobileMenuCurrent.addEventListener("click", handleCurrentMenuItemClick);
   headerMenu.addEventListener("click", handleMenuItemClick);

   const cleanup = setUpTracking();

   return () => {
      mobileMenuTrigger.removeEventListener("click", showMobileMenu);
      mobileMenuClose.removeEventListener("click", hideMobileMenu);
      mobileMenuBack.removeEventListener("click", handleNavigateBack);
      mobileMenuCurrent.removeEventListener("click", handleCurrentMenuItemClick);
      headerMenu.removeEventListener("click", handleMenuItemClick);

      header.querySelectorAll(".is-visible").forEach((elem) => elem.classList.remove("is-visible"));

      cleanup && cleanup();
   };
};

export default Mobile;
